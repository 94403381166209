import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Auth from './Auth';
import Credit from './Credit';
import User from './User';

const PrivateRoute = ({ children }) => {
  const walletAddress = localStorage.getItem('walletAddress');
  return walletAddress ? children : <Navigate to="/Auth/?userLogin=true" />;
};

const RootRoute = () => {
  const walletAddress = localStorage.getItem('walletAddress');
  return walletAddress ? <Navigate to="/user" /> : <Navigate to="/Auth/?userLogin=true" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootRoute />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/credit" element={<Credit />} />
        <Route 
          path="/user" 
          element={
            <PrivateRoute>
              <User />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;

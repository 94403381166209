// http://localhost:3002/Auth/?userLogin=true
// or
// http://localhost:3002/Auth/?userMint=true
// or
// http://localhost:3002/Auth

import React, { useState } from 'react';
import { WebAuthn } from 'othello-webauthn-wallet';
import { useSearchParams, useNavigate } from 'react-router-dom';
import LoadingModal from './LoadingModal';

const urlType = process.env.REACT_APP_ENDPOINT_TYPE;
const apiKey = process.env.REACT_APP_API_KEY;

const Auth = () => {
  const [showSignup, setShowSignup] = useState(true);
  const [username, setUsername] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSignup = async () => {
    setLoading(true);
    try {
      const credential = await WebAuthn.create(username);
      if (credential) {
        const walletAddress = credential.walletAddress;
        localStorage.setItem("walletAddress", walletAddress);

        const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/inputOth3lloCustomer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
          },
          body: JSON.stringify({
            walletAddress: walletAddress,
            tormoId: "",
            publicKeyX: "",
            publicKeyY: ""
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to register wallet address');
        }
        console.log('Signup successful for : ', walletAddress);
        window.dispatchEvent(new Event('storage'));

        const nftMintEventUid = searchParams.get('nftMintEventUid');
        const quantity = parseInt(searchParams.get('quantity'), 10);

        if (nftMintEventUid && quantity) {
          await handlePurchase(walletAddress, nftMintEventUid, quantity);
        } else {
          navigate('/user');
        }
      } else {
        alert("Failed to create wallet. Please try again.");
      }
    } catch (error) {
      console.error("Error creating wallet:", error);
      alert("Signup failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const encoder = new TextEncoder();
      const challenge = encoder.encode("random");
      const credential = await WebAuthn.get(challenge);
      if (credential) {
        console.log('Login successful:', credential.walletAddress);
        localStorage.setItem("walletAddress", credential.walletAddress);
        window.dispatchEvent(new Event('storage'));

        const nftMintEventUid = searchParams.get('nftMintEventUid');
        const quantity = parseInt(searchParams.get('quantity'), 10);

        if (nftMintEventUid && quantity) {
          await handlePurchase(credential.walletAddress, nftMintEventUid, quantity);
        } else {
          navigate('/user');
        }
      } else {
        alert('Login failed. Please try again.');
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async (walletAddress, nftMintEventUid, quantity) => {
    try {
      const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/createTancreCheckoutSession`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
        },
        body: JSON.stringify({
          nftMintEventUid: nftMintEventUid,
          walletAddress: walletAddress,
          quantity: quantity
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const result = await response.json();
      window.location.href = result.checkoutUrl;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("Purchase failed. Please try again.");
    }
  };

  return (
    <div>
      {loading && <LoadingModal />}
      <section id="hero" className="hero-section dark auth">
        {showSignup ? (
          <div className="minting-container signup w-container">
            <h2 className="heading-4">Sign Up</h2>
            <p>Please enter User Name and Proceed with Passkey.<br /></p>
            <div className="div-select-heading"></div>
            <div className="grid-box auth">
              <div id="w-node-_2a1ec2d7-c03f-8e7a-7e26-832521461486-07f2a164" className="form-block w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="66a6f6038a2924d607f2a164" data-wf-element-id="2a1ec2d7-c03f-8e7a-7e26-832521461487">
                  <input
                    className="text-field w-input"
                    maxLength="256"
                    name="name"
                    data-name="Name"
                    placeholder="Enter User Name"
                    type="text"
                    id="name"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </form>
              </div>
              <a className="primary-button dark auth w-button" onClick={handleSignup}>Proceed</a>
            </div>
            <div className="text-block-9">Already have an account? Login <span className="text-span" onClick={() => setShowSignup(false)}>here</span>.</div>
          </div>
        ) : (
          <div className="minting-container login w-container">
            <h2 className="heading-4">Log In</h2>
            <p>Please press the Login button and proceed with Passkey. <br /></p>
            <div className="div-select-heading"></div>
            <div className="grid-box auth login">
              <a className="primary-button dark auth w-button" onClick={handleLogin}>Login</a>
            </div>
            <div className="text-block-9">Don't have an account? Signup <span className="text-span-2" onClick={() => setShowSignup(true)}>here</span>.</div>
            {/*<div className="text-block-11">You do not have an account.</div>*/}
          </div>
        )}
      </section>
    </div>
  );
}

export default Auth;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WebAuthn } from 'othello-webauthn-wallet';
import burger from './images/burger.svg';
import cross from './images/cross.svg';
import copy from './images/copy.svg';
import logo from './images/logo.png';
import LoadingModal from './LoadingModal'; // Assuming you want to show a loading modal during fetch

const urlType = process.env.REACT_APP_ENDPOINT_TYPE;

function User() {
  const location = useLocation();
  const navigate = useNavigate();
  const walletAddress = localStorage.getItem('walletAddress');
  const formattedWalletAddress = walletAddress ? `${walletAddress.slice(0, 6)}....${walletAddress.slice(-4)}` : '';
  const [nfts, setNfts] = useState(JSON.parse(localStorage.getItem('nfts')) || []);
  const [initialLoading, setInitialLoading] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [nftDetailVisible, setNftDetailVisible] = useState(false);
  const [selectedNft, setSelectedNft] = useState(null);
  const [privateKeyVisible, setPrivateKeyVisible] = useState(false);
  const [privateKey, setPrivateKey] = useState('');
  const [loading, setLoading] = useState(false);

  const saveNftsToLocalStorage = (nfts) => {
    localStorage.setItem('nfts', JSON.stringify(nfts));
  };

  const fetchNfts = async (showLoading = false) => {
    if (showLoading) setInitialLoading(true);
    try {
      const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/fetchOth3lloCustomerNft`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
        },
        body: JSON.stringify({ walletAddress })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch NFTs');
      }

      const result = await response.json();
      const storedNfts = JSON.parse(localStorage.getItem('nfts')) || [];

      const storedNftsMap = storedNfts.reduce((map, nft) => {
        map[nft.uid] = nft;
        return map;
      }, {});

      let hasUpdates = false;

      for (const nft of result) {
        if (!storedNftsMap[nft.uid] || JSON.stringify(storedNftsMap[nft.uid]) !== JSON.stringify(nft)) {
          hasUpdates = true;
          break;
        }
      }

      if (hasUpdates) {
        setNfts(result);
        saveNftsToLocalStorage(result);
      }
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      alert('Failed to fetch NFTs. Please try again.');
    } finally {
      if (showLoading) setInitialLoading(false);
    }
  };

  const submitPurchaseHistory = async (nftMintEventUid, walletAddress, stripeId) => {
    try {
      const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/inputOth3lloCustomerPurchaseHistory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
        },
        body: JSON.stringify({
          nftMintEventUid,
          walletAddress,
          stripeId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit purchase history');
      }

      const result = await response.json();
      console.log('Purchase history submitted successfully:', result);
      navigate('/user', { replace: true });
    } catch (error) {
      console.error('Error submitting purchase history:', error);
      alert('Failed to submit purchase history. Please try again.');
    }
  };

  useEffect(() => {
    if (walletAddress) {
      fetchNfts(true);
      const interval = setInterval(() => fetchNfts(false), 10000);
      return () => clearInterval(interval);
    }
  }, [walletAddress]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const stripeMintDone = query.get('stripe_mint_done');
    const nftMintEventUid = query.get('nft_mint_event_uid');
    const walletAddressParam = query.get('wallet_address');
    const stripeId = query.get('session_id');

    if (stripeMintDone === 'true' && nftMintEventUid && walletAddressParam && stripeId) {
      submitPurchaseHistory(nftMintEventUid, walletAddressParam, stripeId);
    }
  }, [location.search]);

  const handleLogout = () => {
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('nfts');
    window.location.href = 'https://tancre.xyz/discover';
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
    setNftDetailVisible(false);
  };

  const showNftDetail = (nft) => {
    setSelectedNft(nft);
    setNftDetailVisible(true);
    setMenuVisible(false);
  };

  const hideNftDetail = () => {
    setNftDetailVisible(false);
    setSelectedNft(null);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert('Copied to clipboard!'))
      .catch(err => alert('Failed to copy text.'));
  };

  const handleExportPrivateKey = async () => {
    setLoading(true);
    try {
      const encoder = new TextEncoder();
      const challenge = encoder.encode("random");
      const credential = await WebAuthn.get(challenge);
      if (credential && credential.walletAddress === walletAddress) {
        const privateKeyHex = toHexString(credential.privateKey);
        setPrivateKey(privateKeyHex);
        setPrivateKeyVisible(true);
      } else {
        alert('Export failed. Wallet address mismatch.');
      }
    } catch (error) {
      console.error("Error during export:", error);
      alert("Export failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  function toHexString(byteArray) {
    return Array.from(byteArray, (byte) => {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    }).join('');
  }

  return (
    <div className="body-portal">
      {initialLoading && <LoadingModal />}

      {/*Header*/}
      <div className="div-block-header">
        <img src={logo} loading="lazy" width="117" sizes="117px" alt="" />
        <div>
          {!nftDetailVisible ? (
            !menuVisible && (
              <img src={burger} loading="lazy" width="25" alt="" onClick={toggleMenu} />
            )
          ) : (
            <img src={cross} loading="lazy" width="30" alt="" onClick={hideNftDetail} />
          )}
          {menuVisible && !nftDetailVisible && (
            <img src={cross} loading="lazy" width="30" alt="" onClick={toggleMenu} />
          )}
        </div>
      </div>
      {/*Header*/}

      {/*Main*/}
      {!menuVisible && !nftDetailVisible && (
        <div className="div-block-main-page">
          <div className="div-block-gallery">
            {nfts.length > 0 && nfts.map((nft, index) => (
              <div key={index} className="div-block-card" onClick={() => showNftDetail(nft)}>
                <div
                  className="div-block-nft-image"
                  style={{
                    backgroundImage: `url(${nft.imageUrl})`,
                    backgroundPosition: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5px',
                    width: '100%',
                  }}
                ></div>
              </div>
            ))}

            <div
              id="w-node-_35f304df-bc21-3680-a05e-a2c75533bbba-49bd7b18"
              className="div-block-card add"
              onClick={() => window.open('https://tancre.xyz/discover', '_blank')}
            >
              <div className="div-block-nft-image add">
                <h1 className="plus">+</h1>
                <div className="text-block-add">Add Credits</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*Main*/}

      {/*Menu*/}
      {menuVisible && !nftDetailVisible && (
        <div className="div-menu">
          <div className="div-block-detail-title">
            <div className="text-block">Wallet</div>
          </div>
          <div className="div-block-detail">
            <div className="div-block-walletaddress">
              <div className="walletaddress">{formattedWalletAddress}</div>
              <img src={copy} loading="lazy" width="20" alt="" onClick={() => handleCopyToClipboard(walletAddress)} />
            </div>
          </div>
          <div className="div-block-detail-title">
            <div className="text-block">Settings</div>
          </div>
          <div className="div-block-detail settings">
            <a  className="button top w-button" onClick={() => window.open('https://tancre.xyz', '_blank')}>ホームページに行く</a>
            <a  className="button between w-button" onClick={handleExportPrivateKey}>プライベートキーを見る</a>
            <a  className="button bottom w-button" onClick={handleLogout}>ログアウト</a>
          </div>
          {privateKeyVisible && (
            <div className="div-block-detail">
              <div className="div-block-walletaddress">
                <div className="walletaddress">プライベートキー : {`${privateKey.slice(0, 6)}....${privateKey.slice(-4)}`}</div>
                <img src={copy} loading="lazy" width="20" alt="" onClick={() => handleCopyToClipboard(privateKey)} />
              </div>
            </div>
          )}
          <div className="div-block-detail-title">
            <div className="text-block">About</div>
          </div>
          <div className="div-block-detail">
            <a  className="button middle w-button" onClick={() => window.open('https://tancre.xyz', '_blank')}>利用規約</a>
          </div>
        </div>
      )}
      {/*Menu*/}

      {/*NFT Detail*/}
      {nftDetailVisible && selectedNft && (
        <div className="div-block-nft">
          <div className="div-block-image">
            <img src={selectedNft.imageUrl} loading="lazy" width="249" alt={selectedNft.nftName} className='gallery-image' />
          </div>
          <div className="div-block-description">
            <h4 className="nft-title">{selectedNft.nftName}</h4>
            <div className="nft-description">
              {selectedNft.description}
            </div>
          </div>
        </div>
      )}
      {/*NFT Detail*/}
    </div>
  );
}

export default User;

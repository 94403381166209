// https://goldcoast.netlify.app/Credit/?creditMint=true&nftMintEventUid=7795db8e-3439-11ef-8932-0a078762ce95&name=HIS Test Donation NFT for Asian Elephant Conservation&description=The Test Asian elephant, whose habitat has been reduced due to deforestation and population growth, has seen its population drop below 40,000. The International Union for Conservation of Nature (IUCN) has classified the Asian elephant as \"\"Endangered (EN)\"\" on&imageUrl=https://media.tormo.co/64c74471-1caf-11ef-8932-0a078762ce95/79612258-2303-11ef-8932-0a078762ce95/a40b983d-3438-11ef-8932-0a078762ce95/1719460431131.jpg&price=1000

import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import LoadingModal from './LoadingModal';

function Credit() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [amount, setAmount] = useState(1);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const urlType = process.env.REACT_APP_ENDPOINT_TYPE;
  const apiKey = process.env.REACT_APP_API_KEY;
  
  useEffect(() => {
    const name = searchParams.get('name');
    const description = searchParams.get('description');
    const imageUrl = searchParams.get('imageUrl');
    const priceParam = searchParams.get('price');
    
    setData({ name, description, imageUrl });
    setPrice(priceParam);
  }, [location.search, searchParams]);

  const handleIncrement = () => {
    setAmount(amount + 1);
  };

  const handleDecrement = () => {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  };

  const handlePurchase = async () => {
    const walletAddress = localStorage.getItem('walletAddress');
    const nftMintEventUid = searchParams.get('nftMintEventUid');
    
    if (!walletAddress) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(`/Auth/?userLogin=true&nftMintEventUid=${nftMintEventUid}&quantity=${amount}`);
      }, 2000);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/createTancreCheckoutSession`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
          },
          body: JSON.stringify({
            nftMintEventUid: nftMintEventUid,
            walletAddress: walletAddress,
            quantity: amount
          })
        });

        if (!response.ok) {
          throw new Error('Failed to create checkout session');
        }

        const result = await response.json();
        window.location.href = result.checkoutUrl;
      } catch (error) {
        console.error("Error creating checkout session:", error);
        alert("Purchase failed. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const totalPrice = price * amount;

  return (
    <div>
      {loading && <LoadingModal />}
      <section id="hero" className="hero-section dark">
        <div className="minting-container w-container">
          <img
            src={data.imageUrl}
            loading="lazy"
            width="240"
            data-w-id="6449f730-ebd9-23f2-b6ad-c6fbce8937f7"
            className="mint-card-image"
            alt={data.name}
          />
          <h2 className="heading-4">{data.name}</h2>
          <p className="paragraph-description">
            {data.description}
          </p>
          <div className="properties">
            <h3 className="rarity-heading">Details</h3>
            <div className="rarity-row roboto-type">
              <div className="rarity-number full">Price / tCO2</div>
              <div className="padded">¥{price}</div>
            </div>
            <div className="rarity-row roboto-type">
              <div className="rarity-number full">Pay with</div>
              <div className="padded">JPY</div>
            </div>
            <div className="rarity-row roboto-type">
              <div className="rarity-number full">Total Price</div>
              <div className="padded">¥{totalPrice}</div>
            </div>
          </div>
          <div className="div-select-heading">
            <h3 className="select-heading">Amounts</h3>
          </div>
          <div className="grid-box">
            <div id="w-node-_08e0bb3b-d3f9-ab0b-86e7-940e48ef8f73-d1ee960e" className="volume-selector-div">
              <a className="primary-button plus-minus w-button" onClick={handleDecrement}>-</a>
              <div className="text-amount">{amount}</div>
              <a className="primary-button plus-minus w-button" onClick={handleIncrement}>+</a>
            </div>
            <a className="primary-button dark w-button" onClick={handlePurchase}>Purchase Now!!</a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Credit;

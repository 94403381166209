import React from 'react';
import './App.css';

const LoadingModal = () => {
  return (
    <div className="loading-modal">
      <div className="loading-content">
        <svg className="loading-svg" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default LoadingModal;